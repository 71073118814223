<template>
  <div class="image-grid">
    <div v-for="image in images" :key="image" class="image-item">
      <div class="image-container">
        <img
          :srcset="`
            ${require(`../assets/screenshot/${image}.webp`)} 400w,
            ${require(`../assets/screenshot/small/${image}.webp`)} 200w
          `"
          sizes="(max-width: 600px) 200px, 400px"
          :src="require(`../assets/screenshot/${image}.webp`)"
          :alt="image"
          loading="lazy"
          data-aos="fade-up"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const images = [
  'home',
  'species',
  'groups',
  'notification',
  'list',
  'breeding',
  'create-animal',
  'date-selector',
  'info',
];
</script>

<style lang="scss">
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;

  @media screen and (max-width: 600px) {
    gap: 8px;
  }
}

.image-item {
  .image-container {
    max-width: 100%; // Ensure the container doesn't exceed the viewport width

    img {
      width: 100%;
      height: auto;
      max-height: 400px;
      object-fit: contain;
      border-radius: 8px;

      @media screen and (max-width: 600px) {
        max-height: 500px;
      }
    }
  }
}
</style>
