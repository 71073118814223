<template>
  <div class="container">
    <h1 class="text-center text--blue">Welcome to Pet Entries</h1>
    <h2 class="text-center">Your Pet's Perfect Journal</h2>
    <p class="mt-4 text-center">
      Pet Entries is a mobile app that allows you to keep track of your
      pets/animals by providing a user-friendly platform for recording and
      managing essential information, ensuring you have a comprehensive record
      of your companions.
    </p>
    <div class="row">
      <!-- First column -->
      <div class="col-md-4">
        <div class="d-flex">
          <ul>
            <li>Keep track of your pet's/animal's information</li>
            <li>Record and manage your pet's/animal's medical history</li>
          </ul>
        </div>
      </div>

      <!-- Second column -->
      <div class="col-md-4">
        <div class="d-flex">
          <ul>
            <li>Upload images of your pet/animal</li>
            <li>Set reminders for important events</li>
          </ul>
        </div>
      </div>

      <!-- Third column -->
      <div class="col-md-4">
        <div class="d-flex">
          <ul>
            <li>Manage multiple pets/animals</li>
            <li>Access your data from any device with ease</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="link-container">
      <a
        :href="link"
        target="_blank"
        rel="noopener noreferrer"
        class="link no-decorative-link"
      >
        {{ link }}
      </a>
      <a
        :href="linkTwo"
        target="_blank"
        rel="noopener noreferrer"
        class="link no-decorative-link"
      >
        {{ linkTwo }}
      </a>
    </div>

    <!-- <div class="d-flex centered">
      <a class="app-button" href="https://apps.apple.com/app/6470149359">
        <img src="../assets/apple.png" alt="App Store" />
        <div class="text-container">
          <div>Download on the</div>
          <div class="store__text">App Store</div>
        </div>
      </a>
    </div> -->
    <!-- <p>Android</p>
    <p>
      Pet Entries is currently only available on iOS. We are working hard to
      bring Pet Entries to Android devices as soon as possible. Please check
      back soon for updates.
    </p> -->
    <!-- <a href="https://play.google.com/store/apps/details?id={your-package-name}"
      >Get it on Google Play</a
    > -->

    <HomeImage />
  </div>
</template>

<script setup lang="ts">
import HomeImage from '@/components/homeImage.vue';

const link = 'https://gitlab.com/Mohammad260/pet-entries.git';
const linkTwo = 'https://gitlab.com/Mohammad260/pet-entries-backend.git';
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2rem !important;
  }
}

h2 {
  font-size: 1.5rem !important;
  margin-bottom: 1rem;
}

a {
  text-decoration: none !important;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    font-size: medium;
    margin: 5px;
  }
}

.app-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: black;
  transition: transform 1s ease-in-out;
  margin: 0px 0 40px 0;

  img {
    height: 35px;
    width: 35px;
  }

  .text-container {
    margin: 0 10px;

    div {
      color: white;
      font-size: small;
      text-decoration: none;
      text-align: center;
    }
    .store__text {
      font-size: medium;
      font-weight: 600;
    }
  }
}
// on hover move up slightly slowly
.app-button:hover {
  transform: translateY(-5px);
}
</style>

<!-- 
  -- playstore icon
  - "Icon made by pictogramerand from www.flaticon.com"
  -  Apple icon by Icons8
 -->
