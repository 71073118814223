<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark sticky">
    <router-link
      class="nav-link navbar-brand"
      active-class="custom-active"
      to="/"
    >
      Pet Entries
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
        <navbar-link name="Home" to="" />
        <navbar-link name="Privacy policy" to="privacy-policy" />
        <navbar-link name="Terms of service" to="terms-of-service" />
        <navbar-link name="Contact" />
      </ul>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavbarLink from './NavbarLink.vue';

export default defineComponent({
  name: 'NavbarComponent',
  components: { NavbarLink },
});
</script>

<style lang="scss" scoped>
nav {
  padding: 15px 20px;
}
.dropdown--padding {
  padding-left: 10px;
}
</style>
