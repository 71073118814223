import { createApp } from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from './App.vue';
import './css/_theme.scss';

import router from './router';
import store from './store';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  duration: 1200,
  once: true,
});

createApp(App).use(store).use(router).mount('#app');
